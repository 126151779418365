import React from 'react';
import './BudgetMeleeUpgradeOrder.css';

class BudgetMeleeUpgradeOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bane_2h_p4_id: 45148,
      bane_2h_p4_price: 0,
      vesta_spear_id: 13905,
      vesta_spear_price: 0,
      sara_sword_id: 11730,
      sara_sword_price: 0,
      necromium_2h_id: 46366,
      necromium_2h_price: 0,
      rock_shell_superior_set_price: 0,
      frem_patch_id: 47658,
      rock_shell_helm_id: 6128,
      rock_shell_plate_id: 6129,
      rock_shell_legs_id: 6130,
      rock_shell_boots_id: 6145,
      rock_shell_gloves_id: 6151,
      saradomin_whisper_id: 25028,
      saradomin_whisper_price: 0,
      bandos_set_id: 40926,
      bandos_set_price: 0,
      statius_helm_id: 13896,
      statius_helm_price: 0,
      statius_chest_id: 13884,
      statius_chest_price: 0,
      statius_legs_id: 13890,
      statius_legs_price: 0,
      bandos_boots_id: 11728,
      bandos_boots_price: 0,
      bandos_gloves_id: 25025,
      bandos_gloves_price: 0,
      lani_spear_id: 48103,
      lani_spear_price: 0,
      masuta_spear_id: 43053,
      masuta_spear_price: 0,
      blade_nymora_id: 37090,
      blade_nymora_price: 0,
      blade_avaryss_id: 37095,
      blade_avaryss_price: 0,
      ripper_claw_id: 36004,
      ripper_claw_price: 0,
      ripper_oh_claw_id: 36008,
      ripper_oh_claw_price: 0,
      laceration_id: 48081,
      laceration_price: 0,
      warrior_ring_id: 6735,
      warrior_ring_price: 0,
      jas_book_id: 51812,
      jas_book_price: 0,
      jaws_id: 52926,
      jaws_price: 0,
      ancient_armour_patch_id: 39047,
      ancient_armour_patch_price: 0,
      augmentor_id: 36725,
      augmentor_price: 0,
      divine_charge_id: 36390,
      divine_charge_price: 0,
      dark_ice_sliver_id: 52045,
      dark_ice_sliver_price: 0,
      dark_ice_shard_id: 52041,
      dark_ice_shard_price: 0
    }
  }

  componentDidMount() {
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + this.state.bane_2h_p4_id)
  		.then(res => res.json())
  		.then(
  			(result) => {
          this.setState({bane_2h_p4_price: this.commafy(result[this.state.bane_2h_p4_id].price) + " GP"});
  			});
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + this.state.vesta_spear_id)
  		.then(res => res.json())
  		.then(
  			(result) => {
          this.setState({vesta_spear_price: this.commafy(result[this.state.vesta_spear_id].price) + " GP"});
  			});
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + this.state.sara_sword_id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({sara_sword_price: this.commafy(result[this.state.sara_sword_id].price) + " GP"});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + this.state.necromium_2h_id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({necromium_2h_price: this.commafy(result[this.state.necromium_2h_id].price) + " GP"});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + this.state.saradomin_whisper_id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({saradomin_whisper_price: result[this.state.saradomin_whisper_id].price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=6128%7C6129%7C6130%7C6145%7C6151%7C47658")
      .then(res => res.json())
      .then(
        (result) => {
          let superior_rock_shell_set_price = (result[this.state.frem_patch_id].price * 5);
          superior_rock_shell_set_price += result[this.state.rock_shell_helm_id].price;
          superior_rock_shell_set_price += result[this.state.rock_shell_plate_id].price;
          superior_rock_shell_set_price += result[this.state.rock_shell_legs_id].price;
          superior_rock_shell_set_price += result[this.state.rock_shell_boots_id].price;
          superior_rock_shell_set_price += result[this.state.rock_shell_gloves_id].price;
          this.setState({rock_shell_superior_set_price: superior_rock_shell_set_price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=40926%7C25025%7C11728")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({bandos_set_price: result[this.state.bandos_set_id].price});
          this.setState({bandos_boots_price: result[this.state.bandos_boots_id].price});
          this.setState({bandos_gloves_price: result[this.state.bandos_gloves_id].price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=13884%7C13890%7C13896%7C39047")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({statius_helm_price: result[this.state.statius_helm_id].price});
          this.setState({statius_chest_price: result[this.state.statius_chest_id].price});
          this.setState({statius_legs_price: result[this.state.statius_legs_id].price});
          this.setState({ancient_armour_patch_price: result[this.state.ancient_armour_patch_id].price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=48103%7C43053%7C37090%7C37095%7C36004%7C36008")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({lani_spear_price: result[this.state.lani_spear_id].price});
          this.setState({masuta_spear_price: result[this.state.masuta_spear_id].price});
          this.setState({blade_nymora_price: result[this.state.blade_nymora_id].price});
          this.setState({blade_avaryss_price: result[this.state.blade_avaryss_id].price});
          this.setState({ripper_claw_price: result[this.state.ripper_claw_id].price});
          this.setState({ripper_oh_claw_price: result[this.state.ripper_oh_claw_id].price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=48081%7C6735%7C51812%7C52926")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({laceration_price: result[this.state.laceration_id].price});
          this.setState({warrior_ring_price: result[this.state.warrior_ring_id].price});
          this.setState({jas_book_price: result[this.state.jas_book_id].price});
          this.setState({jaws_price: result[this.state.jaws_id].price});
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=36725%7C36390")
  		.then(res => res.json())
  		.then(
  			(result) => {
          this.setState({divine_charge_price: result[this.state.divine_charge_id].price});
          this.setState({augmentor_price: result[this.state.augmentor_id].price});
  			});
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=52045%7C52041")
  		.then(res => res.json())
  		.then(
  			(result) => {
          this.setState({dark_ice_shard_price: result[this.state.dark_ice_shard_id].price});
          this.setState({dark_ice_sliver_price: result[this.state.dark_ice_sliver_id].price});
  			});
  }

  commafy(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (
      <div>
        <h1>Budget Melee Upgrade Order</h1>
        <h3>1. Melee Starter Weapons (70-80 Attack)</h3>
        <p>If you're between attack levels 70 through 80, pick the furthest weapon to the left that you have access to and can afford.</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Bane_2h_sword_%2B_4">Bane 2h Sword + 4</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Sunspear">Sunspear</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Vesta%27s_spear">Vesta's Spear</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Saradomin_sword">Saradomin Sword</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Necronium_2h_greataxe_%2B_4">Necronium 2H Greataxe + 4</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Bane_2h_sword_%2B_4_detail.png/120px-Bane_2h_sword_%2B_4_detail.png?ed2c1" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Sunspear_detail.png/130px-Sunspear_detail.png?b22ca" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Vesta%27s_spear_detail.png/100px-Vesta%27s_spear_detail.png?756f4" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Saradomin_sword_detail.png/85px-Saradomin_sword_detail.png?d1cd6" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Necronium_2h_greataxe_%2B_4_detail.png/100px-Necronium_2h_greataxe_%2B_4_detail.png?e707b" />
            </th>
          </tr>
          <tr>
            <th>
              80 Attack
            </th>
            <th>
              78 Attack
            </th>
            <th>
              78 Attack
            </th>
            <th>
              75 Attack
            </th>
            <th>
              70 Attack
            </th>
          </tr>
          <tr>
            <th>
              { this.state.bane_2h_p4_price }
            </th>
            <th>
              Lord of Vampyrium
            </th>
            <th>
              { this.state.vesta_spear_price }
            </th>
            <th>
              { this.state.sara_sword_price }
            </th>
            <th>
              { this.state.necromium_2h_price }
            </th>
          </tr>
        </table>
        <br />
        <h3>2. Cheap Armour (65-70 Defence)</h3>
        <p>Superior rock-shell is very cheap level 65 power armour. The price difference between this set and better armour is significant,
          and it's not worth upgrading further at this point. A superior rock-shell set with a saradomin whisper will cost
          {" " + this.commafy(this.state.rock_shell_superior_set_price + this.state.saradomin_whisper_price) + " GP"} and have a
          29.4 higher damage bonus than a full Bandos set that costs {this.commafy(this.state.bandos_set_price) + " GP"} (this is not
          taking into account the invention perks with the Bandos set).</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Superior_rock-shell_armour_set">Superior rock-shell</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Saradomin%27s_whisper">Saradomin's whisper</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Superior_rock-shell_armour_equipped_%28male%29.png/112px-Superior_rock-shell_armour_equipped_%28male%29.png?a2e7e" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Saradomin%27s_whisper_detail.png/100px-Saradomin%27s_whisper_detail.png?530c9" />
            </th>
          </tr>
          <tr>
            <th>
              65 Defence
            </th>
            <th>
              70 Strength
            </th>
          </tr>
          <tr>
            <th>
              80.9 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
            </th>
            <th>
              36.8 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
            </th>
          </tr>
          <tr>
            <th>
              {this.commafy(this.state.rock_shell_superior_set_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.saradomin_whisper_price) + " GP"}
            </th>
          </tr>
        </table>
        <br />
        <h3>3. Better Armour (70-78 Defence)</h3>
        <p>If you have invention unlocked and can create good perks, the Bandos armour set is a good option for about
          {" " + this.commafy(this.state.bandos_set_price) + " GP"}*. If you don't have invention unlocked, haven't levelled it enough to
          create good armour perks, or can't afford to create good perks, then Statius's Armour is the better choice. Statius's set is
          degrade-to-dust, but it's reasonably cheap. The helm, chest, and legs cost {" " + this.commafy(this.state.statius_helm_price +
          this.state.statius_legs_price + this.state.statius_chest_price) + " GP"} and can be combined with bandos boots and gloves for now.
          <br />*Bandos armour can often cheaper buying each piece individually instead of the set.</p>
          <table>
            <tr>
              <th>
                <a href="https://runescape.wiki/w/Bandos_equipment">Bandos Armour Set</a>
              </th>
              <th>
                <a href="https://runescape.wiki/w/Statius%27s_equipment">Statius's Armour</a> + Bandos boots & gloves
              </th>
            </tr>
            <tr>
              <th>
                <img src="https://runescape.wiki/images/thumb/Bandos_armour_set_detail.png/100px-Bandos_armour_set_detail.png?0e373" />
              </th>
              <th>
                <img src="https://runescape.wiki/images/thumb/Statius%27s_platebody_detail.png/100px-Statius%27s_platebody_detail.png?283b6" />
                <img src="https://runescape.wiki/images/thumb/Bandos_gloves_detail.png/100px-Bandos_gloves_detail.png?24bbc" />
                <img src="https://runescape.wiki/images/thumb/Bandos_boots_detail.png/100px-Bandos_boots_detail.png?1b60e" />
              </th>
            </tr>
            <tr>
              <th>
                70 Defence
              </th>
              <th>
                78 Defence
              </th>
            </tr>
            <tr>
              <th>
                88.3 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
              </th>
              <th>
                98.2 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
              </th>
            </tr>
            <tr>
              <th>
                {this.commafy(this.state.bandos_set_price) + " GP"}
              </th>
              <th>
                {this.commafy(this.state.statius_helm_price + this.state.statius_legs_price + this.state.statius_chest_price +
                  this.state.bandos_boots_price + this.state.bandos_gloves_price) + " GP"}
              </th>
            </tr>
          </table>
        <br />
        <h3>4. Cheap Level 82-85 Weapons</h3>
        <p>The weapons you'll pick at this point will be situational. Your best choice at this point is the Laniakea's Spear if you
          can make use of it. This spear is level 82 with tier 75 accuracy, tier 90 damage, halberd range, and a poison damage
          passive effect. Pick this if the accuracy
          won't negatively affect you. Another good option is the tier 82 Masuta's Warspear for its halberd range.</p>
        <p>At level 85, there are a couple of options, the cheaper ripper claws with tier 85 accuracy and damage as well as a
          damage increasing passive effect, or the Twin Fury blades, which have tier 90 accuracy and tier 80 damage. Use the fury
          blades if you need the high accuracy, use the ripper claws otherwise.</p>
        <p>Pick whichever weapon suits your playstyle from these</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Laniakea%27s_spear">Laniakea's Spear</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Masuta%27s_warspear">Masuta's Warspear</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Ripper_claw">Ripper Claws</a>
            </th>
            <th>
              Blades of <a href="https://runescape.wiki/w/Blade_of_Nymora">Nymora</a> & <a href="https://runescape.wiki/w/Blade_of_Avaryss">Avaryss</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Laniakea%27s_spear_detail.png/150px-Laniakea%27s_spear_detail.png?a58b6" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Masuta%27s_warspear_detail.png/90px-Masuta%27s_warspear_detail.png?d8ec0" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Ripper_claw_detail.png/120px-Ripper_claw_detail.png?04e40" />
              <img src="https://runescape.wiki/images/thumb/Off-hand_ripper_claw_detail.png/120px-Off-hand_ripper_claw_detail.png?7af2b" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Blade_of_Nymora_detail.png/100px-Blade_of_Nymora_detail.png?a5af9" />
              <img src="https://runescape.wiki/images/thumb/Blade_of_Avaryss_detail.png/100px-Blade_of_Avaryss_detail.png?a5af9" />
            </th>
          </tr>
          <tr>
            <th>
              82 Attack
            </th>
            <th>
              82 Attack
            </th>
            <th>
              85 Attack
            </th>
            <th>
              85 Attack
            </th>
          </tr>
          <tr>
            <th>
              Tier 90 damage, tier 75 accuracy, halberd range
            </th>
            <th>
              Halberd range
            </th>
            <th>
              Damage increases as target's health depletes
            </th>
            <th>
              Tier 80 damage, tier 90 accuracy
            </th>
          </tr>
          <tr>
            <th>
              {this.commafy(this.state.lani_spear_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.masuta_spear_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.ripper_claw_price + this.state.ripper_oh_claw_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.blade_nymora_price + this.state.blade_avaryss_price) + " GP"}
            </th>
          </tr>
        </table>
        <br />
        <h3>5. Misc. Equipment & QOL Upgrades</h3>
        <p>Now it's time to pick up a some QOL items and damage boosting equipment.</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Laceration_boots">Laceration Boots</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Warrior_ring_(i)">Warrior Ring(i)</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Scripture_of_Jas">Scripture of Jas</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Jaws_of_the_Abyss">Jaws of the Abyss</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Laceration_boots_detail.png/100px-Laceration_boots_detail.png?d1de3" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Warrior_ring_%28i%29_detail.png/100px-Warrior_ring_%28i%29_detail.png?bbdca" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Scripture_of_Jas_detail.png/100px-Scripture_of_Jas_detail.png?48529" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Jaws_of_the_Abyss_detail.png/100px-Jaws_of_the_Abyss_detail.png?148e7" />
            </th>
          </tr>
          <tr>
            <th>
              80 Defence
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
              85 Defence
            </th>
          </tr>
          <tr>
            <th>
              Allows use of bladed dive with only mainhand melee weapon
            </th>
            <th>
              Cheap ring with a good strength bonus
            </th>
            <th>
              Pocket slot item for a huge DPS increase
            </th>
            <th>
              Generates extra adrenaline from bleeding targets
            </th>
          </tr>
          <tr>
            <th>
              {this.commafy(this.state.laceration_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.warrior_ring_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.jas_book_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.jaws_price) + " GP"}
            </th>
          </tr>
        </table>
        <br />
        <h3>6. Huge Armour Upgrade</h3>
        <p>It's time to move onto some better armour; Superior Statius's Armour is very cheap tier 88 power armour at a fraction
          of the price of tier 90 masterwork armour. Grab a Statius's platebody and platelegs and upgrade them to their superior
          versions using <a href="https://runescape.wiki/w/Ancient_warriors%27_equipment_patch">Ancient Warriors' Equipment Patches</a>.
          This pieces can be augmented and are nearly as good as masterwork armour, just make sure to augment them before equiping
          because this is a degrade-to-dust set.</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Superior_Statius%27s_platebody">Superior Statius's Platebody</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Superior_Statius%27s_platelegs">Superior Statius's Platelegs</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Statius%27s_platebody_detail.png/100px-Statius%27s_platebody_detail.png?283b6" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Statius%27s_platelegs_detail.png/100px-Statius%27s_platelegs_detail.png?be66e" />
            </th>
          </tr>
          <tr>
            <th>
              88 Defence
            </th>
            <th>
              88 Defence
            </th>
          </tr>
          <tr>
            <th>
              33.6 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
            </th>
            <th>
              28.0 <img src="https://runescape.wiki/images/thumb/Strength-icon.png/16px-Strength-icon.png?e67dd" />
            </th>
          </tr>
          <tr>
            <th>
              {this.commafy(this.state.statius_chest_price + this.state.ancient_armour_patch_price + this.state.augmentor_price +
              (this.state.divine_charge_price * 36)) + " GP*"}
            </th>
            <th>
              {this.commafy(this.state.statius_legs_price + this.state.ancient_armour_patch_price + this.state.augmentor_price +
                (this.state.divine_charge_price * 36)) + " GP*"}
            </th>
          </tr>
          <tr>
            <td colspan="2">*Price includes augmentor and divine charge price</td>
          </tr>
        </table>
        <br />
        <h3>7. Best Budget Weapons</h3>
        <p>The Dark Ice swords will generally be your best weapons until you can pick up some T92s. These
          swords are only level 85, but they have tier 88 damage, decouple the cooldowns of hurricane and
          destroy, and allow you to use hurrican with duel-wield.</p>
        <p>In most situations, these swords will be even better than using drygores because of their nice
          passive effects.</p>
        <table>
          <tr>
            <th>
              <a href="https://runescape.wiki/w/Dark_ice_shard">Dark ice shard</a>
            </th>
            <th>
              <a href="https://runescape.wiki/w/Dark_ice_sliver">Dark ice sliver</a>
            </th>
          </tr>
          <tr>
            <th>
              <img src="https://runescape.wiki/images/thumb/Dark_ice_shard_detail.png/100px-Dark_ice_shard_detail.png?4b514" />
            </th>
            <th>
              <img src="https://runescape.wiki/images/thumb/Dark_ice_sliver_detail.png/100px-Dark_ice_sliver_detail.png?461d5" />
            </th>
          </tr>
          <tr>
            <th>
              85 Attack
            </th>
            <th>
              85 Attack
            </th>
          </tr>
          <tr>
            <th>
              {this.commafy(this.state.dark_ice_shard_price) + " GP"}
            </th>
            <th>
              {this.commafy(this.state.dark_ice_sliver_price) + " GP"}
            </th>
          </tr>
        </table>
        <br />
        <h3>8. Beyond "Budget"</h3>
        <p>Here are a few of the upgrades that you'd want to consider once you start making decent cash with PvM (in no specific order):</p>
        <table>
        <tr>
          <th>
            <a href="https://runescape.wiki/w/Cinderbane_gloves">Cinderbane gloves</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Greater_Barge_ability_codex">Greater Barge</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Reaver%27s_ring">Reaver's Ring</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Champion%27s_ring">Champion's ring</a>
          </th>
        </tr>
        <tr>
          <th>
            <img src="https://runescape.wiki/images/thumb/Cinderbane_gloves_detail.png/100px-Cinderbane_gloves_detail.png?b6b7a" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Greater_Barge_ability_codex_detail.png/90px-Greater_Barge_ability_codex_detail.png?26784" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Reaver%27s_ring_detail.png/100px-Reaver%27s_ring_detail.png?d76ae" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Champion%27s_ring_detail.png/100px-Champion%27s_ring_detail.png?b0949" />
          </th>
        </tr>
        <tr>
          <th>
            <a href="https://runescape.wiki/w/Abyssal_scourge">Abyssal Scourge</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Greater_Flurry_ability_codex">Greater Flurry</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Essence_of_Finality_amulet">Essence of Finality</a>
          </th>
          <th>
            <a href="https://runescape.wiki/w/Enchantment_of_heroism">Enchantment of Heroism</a>
          </th>
        </tr>
        <tr>
          <th>
            <img src="https://runescape.wiki/images/thumb/Abyssal_scourge_detail.png/100px-Abyssal_scourge_detail.png?48b2e" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Greater_Flurry_ability_codex_detail.png/90px-Greater_Flurry_ability_codex_detail.png?b9c55" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Essence_of_Finality_amulet_detail.png/120px-Essence_of_Finality_amulet_detail.png?f6c4b" />
          </th>
          <th>
            <img src="https://runescape.wiki/images/thumb/Enchantment_of_heroism_detail.png/150px-Enchantment_of_heroism_detail.png?234b2" />
          </th>
        </tr>
        </table>
      </div>
    );
  }
}

export default BudgetMeleeUpgradeOrder;


function getPriceData(props) {
  console.log("Requesting price data for: " + props.id);
  fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=" + props.id)
		.then(res => res.json())
		.then(
			(result) => {
        console.log(result[props.id].price)
        return result[props.id].price;
			})
}
