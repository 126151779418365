import './Guides.css';
import { useParams } from 'react-router-dom';

import BudgetMeleeUpgradeOrder from './guides/BudgetMeleeUpgradeOrder';
import CraftingGuide from './guides/CraftingGuide';

function Guides() {
    let { guide_id } = useParams();
    switch(guide_id) {
      case 'BudgetMeleeUpgradeOrder':
        return (
          <BudgetMeleeUpgradeOrder />
        );
      case 'CraftingGuide':
        return (
          <CraftingGuide />
        );
    }
}

export default Guides;
