import './GuidesHome.css';

function GuidesHome() {

    return (
      <div className="GuidesHome">
        <h1> GUIDES </h1>
        <a href="/guides/BudgetMeleeUpgradeOrder">Budget Melee Upgrade Order</a><br />
        <a href="/guides/CraftingGuide">Crafting Guide</a>
      </div>
    );
}

export default GuidesHome;
