import './App.css';

import NavBar from './NavBar';
import Home from './pages/Home';
import GuidesHome from './pages/GuidesHome';
import Guides from './pages/Guides';

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Redirect
} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div className="PageContents">
        <NavBar />
        <div className="App-body">
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
	      <Route path="/guides" element={<GuidesHome />} />
	      <Route path="/guides/:guide_id" element={<Guides />} />
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
