import './Home.css';

function Home() {
  return (
    <div className="Home">
      <iframe className="embededYouTubeVideo" src="https://www.youtube.com/embed/xclwB0bZ1k4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
}

export default Home;
