import React from 'react';
import './CraftingGuide.css';

class CraftingGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingData: '',
      gemData: [],
      gemTable: '',
      battlestaffData: [],
      battlestaffTable: [],
      dragonhideData: [],
      dragonhideTable: '',
      combinedData: [],
      combinedTable: '',
      sortAscending: true,
      flaxPrice: 0,
      bowStringPrice: 0
    }
  }

  commafy(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // ITEM FORMAT: [ 0 Level  | 1 Name | 2 XP/Hour | 3 GP/XP | 4 XP/EA | 5 Cost/EA ]
  itemToTR(item) {
    return(
      <tr key={ item[1] }>
        <td>{ item[0] }</td>
        <td>{ item[1] }</td>
        <td>{ this.commafy(item[2]) }</td>
        <td>{ item[3] }</td>
      </tr>
    );
  }

  itemDataToTable(itemData) {
    return (
      <table>
        <tr>
          <th onClick={ function sort() {
            this.state.combinedData.sort(function compare(a,b) {
              if(a[0] == b[0])
                if(this.state.sortAscending)
                  return b[2] - a[2];
                else {
                  return a[2] - b[2]
                }
              if(this.state.sortAscending)
                return a[0] - b[0];
              else
                return b[0] - a[0]
            }.bind(this));
            let combinedTable = this.itemDataToTable(this.state.combinedData);
            this.setState({combinedTable: combinedTable, sortAscending: !this.state.sortAscending});
          }.bind(this) }>Level <img src="https://storage.cloud.google.com/lemagetank-site-bucket/cdn/resources/images/sort-arrows-icon.svg" className="SortIcon" /></th>
          <th>Method</th>
          <th onClick={ function sort() {
            this.state.combinedData.sort(function compare(a,b) {
              if(this.state.sortAscending)
                return b[2] - a[2];
              else
                return a[2] - b[2];
            }.bind(this));
            let combinedTable = this.itemDataToTable(this.state.combinedData);
            this.setState({combinedTable: combinedTable, sortAscending: !this.state.sortAscending});
          }.bind(this) }>XP/Hr <img src="https://storage.cloud.google.com/lemagetank-site-bucket/cdn/resources/images/sort-arrows-icon.svg" className="SortIcon" /></th>
          <th onClick={ function sort() {
            this.state.combinedData.sort(function compare(a,b) {
              if(this.state.sortAscending)
                return b[3] - a[3];
              else
                return a[3] - b[3];
            }.bind(this));
            let combinedTable = this.itemDataToTable(this.state.combinedData);
            this.setState({combinedTable: combinedTable, sortAscending: !this.state.sortAscending});
          }.bind(this) }>GP/XP <img src="https://storage.cloud.google.com/lemagetank-site-bucket/cdn/resources/images/sort-arrows-icon.svg" className="SortIcon" /></th>
        </tr>
        { itemData.map((item, i) => this.itemToTR(item)) }
      </table>
    );
  }

  formatInitialTrainingData() {
    console.log("Formatting training data");
    let combinedData = [];
    let gemData = [];
    let dragonhideData = [];
    let battlestaffData = []

    let battlestaves = this.state.trainingData.battlestaves;
    let dragonhides = this.state.trainingData.dragonhide;
    let gems = this.state.trainingData.gems;

    for(const key in gems) {
      let name = key.replaceAll('_', ' ');
      let level = Number(gems[key]['level']);
      let hourly = gems[key]['hourly_rate'];
      let gpPerXp = gems[key]['gp_per_xp'].toFixed(3);
      let xpEach = gems[key]['xp'];
      let netCost = gems[key]['value'] - gems[key]['cost'];
      let row = [level, name, hourly, gpPerXp, xpEach, netCost];
      gemData.push(row);
      combinedData.push(row);
    }

    for(const key in battlestaves) {
      let name = key.replaceAll('_', ' ');
      let level = Number(battlestaves[key]['level']);
      let hourly = battlestaves[key]['hourly_rate'];
      let gpPerXp = battlestaves[key]['gp_per_xp'].toFixed(3);
      let xpEach = battlestaves[key]['xp'];
      let netCost = battlestaves[key]['value'] - battlestaves[key]['cost'];
      let row = [level, name, hourly, gpPerXp, xpEach, netCost];
      gemData.push(battlestaffData);
      combinedData.push(row);
    }

    for(const key in dragonhides) {
      let name = key.replaceAll('_', ' ');
      let level = Number(dragonhides[key]['level']);
      let hourly = dragonhides[key]['hourly_rate'];
      let gpPerXp = dragonhides[key]['gp_per_xp'].toFixed(3);
      let xpEach = dragonhides[key]['xp'];
      let netCost = dragonhides[key]['value'] - dragonhides[key]['cost'];
      let row = [level, name, hourly, gpPerXp, xpEach, netCost];
      gemData.push(dragonhideData);
      combinedData.push(row);
    }

    combinedData.sort(function compare(a,b) {
      if(a[0] == b[0]) {
        return b[2] - a[2];
      }
      return a[0] - b[0];
    });

    let combinedTable = this.itemDataToTable(combinedData);

    this.setState({combinedData: combinedData, gemData: gemData, dragonhideData: dragonhideData,
      battlestaffData: battlestaffData, combinedTable: combinedTable});
  }

  componentDidMount() {
    fetch("https://www.lemagetank.com/craftingdata")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({trainingData: result}, function() {
            this.formatInitialTrainingData();
          });
        });
    fetch("https://api.weirdgloop.org/exchange/history/rs/latest?id=1779%7C1777")
  		.then(res => res.json())
  		.then(
  			(result) => {
          this.setState({flaxPrice: result[1779].price, bowStringPrice: result[1777].price});
  			});
  }

  render() {
    return (
      <div>
        <h1>Crafting</h1>
        <h3>Levels 1-19</h3>
        <p>For levels 1-19, craft bowstrings from flax for 15 XP each.
        Without boosts, This will require 298 bowstrings for a total net profit of
        { " " + this.commafy((this.state.bowStringPrice - this.state.flaxPrice) * 298) } GP</p>
        <br />
        <h3>Levels 20+</h3>
        <p>Pick the method from the below table with the highest XP rate that you can
        afford.</p>
        <p>*XP rates assume 4,000 gems, 2,600 battlestaves, or 1600 dragonhide items per hour.</p>
        { this.state.combinedTable }
      </div>
    );
  }
}

export default CraftingGuide;
