import './NavBar.css';

function NavBar() {
  return (
    <div className="NavBar">
      <div className="ButtonContainer">
        <button className="NavBarButton" onClick={() => {navigate("/")}}> Home </button>
        <button className="NavBarButton" onClick={() => {navigate("/videos")}}> Videos </button>
        <button className="NavBarButton" onClick={() => {navigate("/guides")}}> Guides </button>
      </div>
      <div className="SocialsContainer">
        <button className="NavBarSocial" onClick={() => {navigate("https://www.twitch.tv/lemagetank")}}><img src="https://storage.cloud.google.com/lemagetank-site-bucket/cdn/resources/images/twitch-icon.png" className="SocialImage" /></button>
        <button className="NavBarSocial" onClick={() => {navigate("https://www.youtube.com/channel/UCnYG3ZjXmzG9KnR_E-ujjJw")}}><img src="https://storage.cloud.google.com/lemagetank-site-bucket/cdn/resources/images/youtube-icon.png" className="SocialImage" /></button>
      </div>
    </div>
  );
}

function navigate(path) {
  window.location = path;
}

export default NavBar;
